<template>
    <div>
        <!-- Start Footer Area  -->
        <footer v-if="data.showFooter" class="rn-footer footer-style-default variation-two">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6 col-5">
                            <div class="rn-footer-widget">
                                <h4 class="title">爱人力</h4>
                                <div class="inner">
                                  <div class="qr-code">
                                    <div>
                                      <img src="../../../assets/images/arl/qrcode.png" />
                                      <p>{{isMobile?'微信客服':'爱人力官网微信客服'}}</p>
                                    </div>
                                    <div>
                                      <img src="../../../assets/images/arl/qrcode.png" />
                                      <p>{{isMobile?'微信公众号':'爱人力官网微信公众号'}}</p>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-5 mobile-none">
                            <div class="rn-footer-widget rn-footer-widget-right ">
                                <h4 class="title">关于我们</h4>
                                <div class="inner">
                                    <ul class="footer-link link-hover">
                                        <li>
                                            <router-link to="#">公司简介</router-link>
                                        </li>
                                        <li>
                                            <router-link to="#">团队介绍</router-link>
                                        </li>
                                        <li>
                                            <router-link to="#">加入我们</router-link>
                                        </li>
                                        <li>
                                            <router-link to="#">隐私条款</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-7">
                            <div class="rn-footer-widget rn-footer-widget-pdl20">
                                <h4 class="title">联系我们</h4>
                                <div class="inner">
                                    <div class="about-us">
                                        <p>地址位置：北京市昌平区珠江摩尔大厦5号楼</p>
                                    </div>
                                    <div class="about-us">
                                        <p>客服热线：010-60702771</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
<!--                    <div class="acea-row row-between row-bottom mobile-none">-->
<!--                        <span>友情链接：</span>-->
<!--                        <span>报销系统</span>-->
<!--                        <span>企业服务</span>-->
<!--                        <span>企业直播</span>-->
<!--                        <span>背景调查</span>-->
<!--                        <span>电子签名</span>-->
<!--                        <span>大数据</span>-->
<!--                        <span>客户体验CEM</span>-->
<!--                        <span>云表格</span>-->
<!--                        <span>人事系统</span>-->
<!--                        <span>招聘系统</span>-->
<!--                    </div>-->
                    <Separator/>
                    <div class="acea-row row-between row-bottom mobile-none">
                        <span>Copyright © 2024 北京爱人力科技有限公司 版权所有</span>
                        <span> <a target="_blank" href="https://beian.miit.gov.cn/">京ICP备2024088414号</a></span>
                        <span>客户建议及合作：hezuo@irenli.com</span>
                    </div>
                </div>
            </div>
            <!-- <Newsletter v-if="data.showNewsletter"/> -->
        </footer>
        <!-- End Footer Area  -->

        <!-- <Copyright v-if="data.showCopyright"/> -->

        <ScrollTop/>
    </div>
</template>

<script>
    import Copyright from './Copyright'
    import Button from '../../elements/button/Button'
    import Icon from '../../icon/Icon'
    import ScrollTop from './ScrollTop'
    import Logo from '../../elements/logo/Logo'
    import Newsletter from './Newsletter'
    import BlogPostMixin from '../../../mixins/BlogPostMixin-arl'

    export default {
        name: 'Footer',
        components: {Newsletter, Logo, ScrollTop, Icon, Button, Copyright},
        mixins: [BlogPostMixin],
        props: {
            data: {
                default: null
            }
        }
    }
</script>
<style lang="scss" scoped>
 $sm-layout:'only screen and (max-width: 767px)';
.rn-footer{
    .footer-top{
        @media #{$sm-layout} {
            padding:18px 0 0 0;
        }
        .container{
            .rn-footer-widget-right{
                text-align:right;
            }
            .rn-footer-widget-pdl20{
                padding-left:120px;
                @media #{$sm-layout} {
                       padding:0;
                    }
            }
            .rn-footer-widget{
                // text-align:center;
                .title{
                    color: #fff;
                    font-size:24px;
                    @media #{$sm-layout} {
                       display:none;
                    }
                }
                .inner{
                    .about-us{
                        background: #161523!important;;
                        padding:8px 0 8px 50px;
                        margin:40px 0;
                        border-radius:8px;
                        @media #{$sm-layout} {
                            padding:5px 20px 4px 13px;
                            border-radius:2px;
                            margin: 18px 0
                            }
                        p{
                            margin-bottom:0;
                            color:#828698;
                            font-size:20px;
                            @media #{$sm-layout} {
                              font-size:9px;
                            }
                        }
                    }
                    .foot-link{
                      
                    }
                    a{color:#828698;font-size:22px; margin:13px 0; }  
                    .qr-code{
                        display:flex;
                        justify-content: space-between;
                        div{
                            text-align: center;
                        }
                        img{
                            padding:12px;
                            background:#FFF;
                            width:160px;
                            height:160px;
                            border-radius:4px;
                            @media #{$sm-layout} {
                                width:58px;
                                height:58px;
                                padding:4px;
                            }
                        }
                        p{
                            margin-top:16px;
                            color:#828698 ;
                            font-size:20px;
                            @media #{$sm-layout} {
                                font-size:10px;
                            }
                        }
                    }   
                }
            }
        }
 }
}
.row-bottom{
    padding:20px 0;
    border-bottom:1px solid #828698;
    font-size:20px;
    &:nth-last-child(1){
        border:none;
        font-size:18px;
    }
}
</style>