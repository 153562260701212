<template>
    <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="true" :show-top-header="false" buy-button-class="btn-default btn-small round empty">
        <!-- Start 轮播 -->
        <div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-950 bg-overlay bg_image"
                         :style="{'background-image': `url(${require(`@/assets/images/bg/${slider.image}.jpg`)})`}">
                        <div class="container">
                            <div class="row row--10 align-items-center">
                                <div class="order-2 order-lg-1 ">
                                    <div class="inner text-start">
                                        <h1 class="title" v-html="slider.title"/>
                                        <p class="description description-one" v-html="slider.description"/>
                                        <p class="description" v-html="slider.description1"/>
                                        <!-- <div class="button-group mt--30">
                                            <a class="btn-default"
                                               href="#">
                                                PURCHASE NOW
                                            </a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <!-- End 轮播 -->

        
        <!-- Start 爱人力 更智能 更轻简 更顺畅-->
        <div class="blog-area rn-section-gap arl-zn">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="爱人力 更智能 更轻简 更顺畅"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="100 + index"
                         v-for="(blog, index) in posts"
                         :key="index"
                         v-if="index < 3">
                        <BlogPost :blog="blog"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 爱人力 更智能 更轻简 更顺畅 -->
        
        <!-- Start 爱人力 销售｜轻松获客 智慧销售 -->
        <div class="blog-area rn-section-gap bg-image22">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            :title="isMobile?'爱人力 销售':'爱人力 销售｜轻松获客 智慧销售'"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-7 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="102"
                         v-for="(blog, index) in posts"
                         :key="index"
                         v-if="index==0">
                        <div class="sell-left">
                            <div class="sell-left-inside bg-image23" @mouseenter="handleMouseEnter1(1)"
                            :class="{ hovered1: 1 === hoverIndex1 }"
                            @mouseleave="startHover(1)" @mouseover="stopHover">
                                <p>客户全生命周期管理 </p>
                                <p>精细化销售管理，从商机到回款的客户全生命周期管理，驱动业务增长。</p>
<!--                                <p>查看全部</p>-->
                                <!-- <Icon name="arrow-right-circle"/> -->
                            </div>
                            <div class="sell-left-inside bg-image23" @mouseenter="handleMouseEnter1(2)"
                            :class="{ hovered1: 2 === hoverIndex1 }"
                            @mouseleave="startHover(2)" @mouseover="stopHover">
                                <p>精细化客户管理 </p>
                                <p>自定义标签沉淀客户画像，加速商机转化。</p>
<!--                                <p>查看全部</p>-->
                            </div>
                            <div class="sell-left-inside bg-image23" @mouseenter="handleMouseEnter1(3)"
                            :class="{ hovered1: 3 === hoverIndex1 }"
                            @mouseleave="startHover(3)" @mouseover="stopHover">
                                <p>客户全面记录跟踪 </p>
                                <p>客户基础信息、跟进记录、商机信息、回款信息等全面记录，助力企业精准营销，深化客户关系。</p>
<!--                                <p>查看全部</p>-->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-12 col-12 mt--30 sell-right"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="106">
                         <BlogPost :blog="blogObj"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 爱人力 销售｜轻松获客 智慧销售-->
        <!-- Start 轮播-middle -->
                 <!-- Start 爱人力 销售｜轻松获客 智慧销售 -->
        <div class="blog-area rn-section-gap arl-zhaopin">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            :title="isMobile?'爱人力 招聘':'爱人力 招聘｜降本增效 招聘更快捷'"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="106"
                         >
                            <el-carousel :interval="5000" type="card" height="547px" >
                                <el-carousel-item class="img-item">
                                  <img src="@/assets/images/arl/carousel-item-1.png"/>
                                  <p>客户招聘需求管理</p>
                                </el-carousel-item>
                                <el-carousel-item  class="img-item">
                                  <img src="@/assets/images/arl/carousel-item-2.png"/>
                                  <p>招聘流程自动化</p>
                                </el-carousel-item>
                                <el-carousel-item class="img-item">
                                  <img src="@/assets/images/arl/carousel-item-3.png"/>
                                  <p>人才库建设与激活</p>
                                </el-carousel-item>
                            </el-carousel>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 爱人力 销售｜轻松获客 智慧销售-->
        <!-- Start 爱人力 HRO -->
        <div class="blog-area rn-section-gap bg-hro ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="爱人力 HRO｜简化 ‘入转调离’ 提升员"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="102"
                         v-for="(blog, index) in posts"
                         :key="index"
                         v-if="index==0">
                        <div class="sell-left">
                            <div class="sell-left-inside bg-image25" @mouseenter="handleMouseEnter2(1)"
                            :class="{ hovered1: 1 === hoverIndex1 }"
                            @mouseleave="startHover(1)" @mouseover="stopHover">
                                <p>员工管理 </p>
                                <p>更懂人事场景的工作流，入转调离轻松办理。</p>
<!--                                <p>查看全部</p>-->
                            </div>
                            <div class="sell-left-inside bg-image25" @mouseenter="handleMouseEnter2(2)"
                            :class="{ hovered1: 2 === hoverIndex1 }"
                            @mouseleave="startHover(2)" @mouseover="stopHover">
                                <p>薪酬管理 </p>
                                <p>系统赋能，查阅个人工资明细与客户级发放记录，透明高效，让薪酬管理尽在掌握。</p>
<!--                                <p>查看全部</p>-->
                            </div>
                            <div class="sell-left-inside bg-image25" @mouseenter="handleMouseEnter2(3)"
                            :class="{ hovered1: 3 === hoverIndex1 }"
                            @mouseleave="startHover(3)" @mouseover="stopHover">
                                <p>社保管理 </p>
                                <p>全面集成员工社保管理，为企业与员工构筑坚实保障之盾。</p>
<!--                                <p>查看全部</p>-->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 sell-right"
                         data-aos="slide-up"
                         data-aos-duration="900"
                         :data-aos-delay="106"
                         >
                        <BlogPost :blog="blogObj1"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 爱人力 HRO -->
        <!-- Start 爱人力 选择爱人力 -->
        <div class="blog-area rn-section-gap bg-image24 ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="谁选择了爱人力"
                            :description="isMobile?'':'从求职者到招聘专员，都能共享高效招聘体验，让管理者洞察数据，经本增效，塑造企业专业形象。'"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <block v-if="!isMobile">
                    <div class="row row--15">
                        <div class="col-lg-4 col-md-6 col-sm-12 col-5 mt--30"
                            data-aos="slide-up"
                            data-aos-duration="800"
                            :data-aos-delay="102"
                            v-for="(blog, index) in chooseArlList"
                            :key="index"
                            v-if="index<3">
                            <div class="choose-arl">
                                <div class="acea-row row-left">
                                    <img :src="blog.img" alt="">
                                    <div>{{ blog.title }}</div>
                                </div>
                                <p>{{ blog.content }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row row--15 row2">
                        <div class="col-lg-4 col-md-6 col-sm-12 col-5 mt--30"
                            data-aos="slide-up"
                            data-aos-duration="900"
                            :data-aos-delay="106"
                            v-for="(blog, index) in chooseArlList1"
                            :key="index"
                            v-if="index<2">
                            <div class="choose-arl">
                                <div class="acea-row row-left">
                                    <img :src="blog.img" alt="">
                                    <div>{{ blog.title }}</div>
                                </div>
                                <p>{{ blog.content }}</p>
                            </div>
                        </div>
                    </div>
                </block>
                <div v-else class="row row--15 row2">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6 mt--30"
                            data-aos="slide-up"
                            data-aos-duration="900"
                            :data-aos-delay="106"
                            v-for="(blog, index) in chooseArlList.concat(chooseArlList1)"
                            :key="index"
                            v-if="index<6">
                            <div class="choose-arl">
                                <div class="acea-row row-left">
                                    <img :src="blog.img" alt="">
                                    <div>{{ blog.title }}</div>
                                </div>
                                <p>{{ blog.content }}</p>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <!-- End 爱人力 选择爱人力-->
        <!-- Start 爱人力-服务 -->
        <div class="blog-area rn-section-gap bg-arl">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="爱人力是如何服务的？"
                            :description="isMobile?'':'提供系统化上线流程，专业咨询，打造多维度协同的数字化人力资源服务。'"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-5 col-md-6 col-sm-12 col-12 mt--30 arl-service-left"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="102"
                         v-for="(blog, index) in posts"
                         :key="index"
                         v-if="index == 4">
                         <div class="how-service-left">
                            <img :src="howServiceImg"/>
                         </div>
                         
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="102"
                         v-for="(blog, index) in posts"
                         :key="index"
                         v-if="index == 4">
                         <div class="how-service-right">
                            <div class="acea-row row-left" @mouseenter="handleMouseEnter(index)" v-for="(item, index) in 5" :key="index"
                            :class="{ hovered: index === hoverIndex }"
                            @mouseleave="startHover(index)" @mouseover="stopHover">
                                <img :src="require(`@/assets/images/arl/how-service-icon${index+1}.png`)" alt="">
                              <div>完美契合您人力资源机构的独特需求，让管理更加个性化与高效。</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 爱人力-服务-->
        <!-- Start 爱人力-知名企业 -->
        <div class="blog-area rn-section-gap bg-arl bg-arl-mobile">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="知名企业都在用人力生态云"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-2 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="102"
                         v-for="(blog, index) in posts"
                         :key="index"
                         v-if="index < 10 && index > 4">
                         <BlogPost :blog="blog"/>
                    </div>
<!--                    <div class="col-lg-2 col-md-6 col-sm-12 col-12 mt&#45;&#45;30"-->
<!--                         data-aos="slide-up"-->
<!--                         data-aos-duration="800"-->
<!--                         :data-aos-delay="102"-->
<!--                         v-for="(blog, index) in posts"-->
<!--                         :key="index"-->
<!--                         v-if="index < 10 && index > 4">-->
<!--                         <BlogPost :blog="blog"/>-->
<!--                    </div>-->
<!--                    <div class="col-lg-2 col-md-6 col-sm-12 col-12 mt&#45;&#45;30"-->
<!--                         data-aos="slide-up"-->
<!--                         data-aos-duration="800"-->
<!--                         :data-aos-delay="102"-->
<!--                         v-for="(blog, index) in posts"-->
<!--                         :key="index"-->
<!--                         v-if="index < 10 && index > 4">-->
<!--                         <BlogPost :blog="blog"/>-->
<!--                    </div>-->
<!--                    <div class="col-lg-2 col-md-6 col-sm-12 col-12 mt&#45;&#45;30"-->
<!--                         data-aos="slide-up"-->
<!--                         data-aos-duration="800"-->
<!--                         :data-aos-delay="102"-->
<!--                         v-for="(blog, index) in posts"-->
<!--                         :key="index"-->
<!--                         v-if="index < 10 && index > 4">-->
<!--                         <BlogPost :blog="blog"/>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
        <!-- End 爱人力-知名企业-->
        <!-- Start 爱人力-全员体验最好的平台 -->
        <div class="blog-area rn-section-gap bg-image27 bg-arl-mobile">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="爱人力 | 全员体验更好的人力资源SaaS平台"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="102">
                         <div class="tiyan">
                            <div class="tiyan-inside acea-row row-between">
                                <div class="inside-left">请输入您的电话</div>
                                <div class="inside-right">免费试用</div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 爱人力-全员体验最好的平台-->
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import SectionTitle from '../../components/elements/sectionTitle-arl/SectionTitle'
    import Separator from '../../components/elements/separator/Separator'
    import VueSlickCarousel from 'vue-slick-carousel'
    import BlogPost from '../../components/blog/BlogPost-arl'
    import BlogPostMixin from '../../mixins/BlogPostMixin-arl'
    import Testimonial from '../../components/elements/testimonial/Testimonial'
    import Portfolio from '../../components/elements/portfolio/Portfolio'
    import ServiceFive from '../../components/elements/service/ServiceFive'
    import Progressbar from '../../components/elements/progressbar/Progressbar'
    import Icon from '../../components/icon/Icon'

    export default {
        name: 'DigitalAgency',
        components: {
            Icon,
            Progressbar,
            ServiceFive,
            Portfolio,
            Testimonial,
            BlogPost,
            Separator,
            SectionTitle,
            Layout,
            VueSlickCarousel
        },
        mixins: [BlogPostMixin],
        data() {
            return {
                hoverIndex:null,
                hoverIndex1:null,
                hoverInterval:null,
                chooseArlList:[
                    {
                        title:'服务面广',
                        content:'服务业务涵盖广泛的人力资源服务机构，业务包括劳务派遣、岗位外包、人事外包、灵活用工等。',
                        img: require(`@/assets/images/arl/choose-arl-1.png`),
                    },
                    {
                        title:'流动性大',
                        content:'服务客户多，客户人员流动性过大、每月增减员过于频繁的人力资源服务机构。',
                        img: require(`@/assets/images/arl/choose-arl-2.png`),
                    },
                    {
                        title:'财务繁琐',
                        content:'采用线下管理财务收支账目，账目模糊不清，难以精确追踪每笔结算账单收支细节的人力资源服务机构。',
                        img: require(`@/assets/images/arl/choose-arl-3.png`),
                    }
                ],
                chooseArlList1:[
                    {
                        title:'效率低下',
                        content:'服务人员工作负担沉重，导致效率低下、数据处理错误频发以及流程混乱不清的人力资源服务机构。',
                        img: require(`@/assets/images/arl/choose-arl-4.png`),
                    },
                    {
                        title:'对账困难',
                        content:'线下方式管理员工薪酬与社保，因数据计算量庞大及人工计算而面临出错风险的人力资源服务机构。',
                        img: require(`@/assets/images/arl/choose-arl-5.png`),
                    }
                ],
                blogObj:{
                    id: '4',
                    img: require(`@/assets/images/blog/blog-04.jpg`),
                    author: 'Jannin',
                    authorId: '4',
                    view: '70 views',
                    publishedAt: '25 Aug 2022',
                    // title: 'Top 5 Lessons About Corporate To Learn Before.',
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Marketing', 'UX Design', 'Business'],
                    tags: ['App', 'Business', 'Software', 'Design']
                },
                blogObj1:{
                    id: '5',
                    img: require(`@/assets/images/arl/hro1.png`),
                    author: 'Jannin',
                    authorId: '4',
                    view: '70 views',
                    publishedAt: '25 Aug 2022',
                    // title: 'Top 5 Lessons About Corporate To Learn Before.',
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Marketing', 'UX Design', 'Business'],
                    tags: ['App', 'Business', 'Software', 'Design']
                },
                howServiceImg:require('@/assets/images/arl/how-service-1.png'),
                sliderSetting: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 993,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                },
                sliderSetting1: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    autoplay: true, // 自动播放
                    autoplaySpeed: 5000, // 自动播放速度
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 993,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                },
                sliderData: [
                    {
                        image: 'bg-image-6',
                        title: '爱人力,&nbsp;&nbsp;人力资源的全方位管家',
                        description: '打造您的专属人力资源外包服务智能平台',
                        description1: '专为人力资源公司量身定制，实现全流程线上化、智能化的高效管理。'
                    },
                    // {
                    //     image: 'bg-image-14',
                    //     title: 'Creative Agency.',
                    //     description: 'We help our clients succeed by creating brand identities, digital experiences, and print materials.'
                    // },
                    // {
                    //     image: 'bg-image-15',
                    //     title: 'Startup Agency.',
                    //     description: 'We help our clients succeed by creating brand identities, digital experiences, and print materials.'
                    // },
                ],
                testimonialData: [
                    {
                        image: 'testimonial-01',
                        name: 'Sr Janen Sara',
                        designation: 'Sr Product Designer',
                        location: 'CO Miego, AD, USA',
                        description: '“Proin libero vel lorem dui lupus est aliquet luctus purus justo eget libero sed lorem.„',
                    },
                    {
                        image: 'testimonial-02',
                        name: 'Afsana Nila',
                        designation: 'App Developer',
                        location: 'Bangladesh',
                        description: '“Proin libero vel lorem dui lupus est aliquet luctus purus justo eget libero sed lorem.„',
                    },
                    {
                        image: 'testimonial-03',
                        name: 'Afanan Sifa',
                        designation: 'Accounts Manager',
                        location: 'Poland',
                        description: '“Proin libero vel lorem dui lupus est aliquet luctus purus justo eget libero sed lorem.„',
                    },
                ],
                progressCircleData: [
                    {
                        id: 1,
                        title: 'Design',
                        percentage: 85,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 2,
                        title: 'Photoshop',
                        percentage: 60,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 3,
                        title: "Application",
                        percentage: 70,
                        subtitle: "Presentation your skill",
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 4,
                        title: 'Development',
                        percentage: 95,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                ],
                portfolioData:  [
                    {
                        id: 1,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        title: 'App Development',
                        subtitle: 'Branded client',
                        date: '30 May 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 2,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        title: 'Business Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 3,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        title: 'Photoshop Design',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'art',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 4,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        title: 'Native Application',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-04.jpg`)
                        }
                    },
                    {
                        id: 5,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        title: 'Vue Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 6,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        title: 'App Installment',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-06.jpg`)
                        }
                    }
                ]
            }
        },
        methods:{
            startHover(index) {
                if(this.isMobile){
                    return
                }
                this.hoverIndex1 = index;
                this.hoverIndex = index;
                if (this.hoverInterval) clearInterval(this.hoverInterval);
                this.hoverInterval = setInterval(() => {
                if (this.hoverIndex1 === 3) {
                    this.hoverIndex1 = 1;
                } else {
                    this.hoverIndex1++;
                }
                if (this.hoverIndex === 4) {
                    this.hoverIndex = 0;
                } else {
                    this.hoverIndex++;
                }
                this.handleMouseEnter1(this.hoverIndex1)
                this.handleMouseEnter2(this.hoverIndex1)
                this.handleMouseEnter(this.hoverIndex)
                }, 6000); // 每1000毫秒切换一次
            },
            stopHover() {
                if (this.hoverInterval) {
                clearInterval(this.hoverInterval);
                this.hoverInterval = null;
                this.hoverIndex1 = null
                this.hoverIndex = null
                }
            },
            handleMouseEnter(v) {
              this.howServiceImg = require(`@/assets/images/arl/how-service-${v+1}.png`)
            },
            handleMouseEnter1(v) {
              this.blogObj.img = require(`@/assets/images/arl/masakaI${v}.png`)
            },
            handleMouseEnter2(v) {
              this.blogObj1.img = require(`@/assets/images/arl/hro${v}.png`)
            }
        },
        created(){
            this.startHover(1)
        }
    }
</script>
<style lang="scss" scoped>
    $sm-layout:'only screen and (max-width: 767px)';
    .bg-image22 {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        background-image: url(../../assets/images/bg/bg-image-22.jpg);
    }
    .bg-image23 {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        background-image: url(../../assets/images/bg/bg-image-23.png);
        @media #{$sm-layout} {
            background: #FFF;
            border-radius:10px;
            height:96px;
            margin-top:10px;
        }
    }
    .sell-left{
        display:flex;
        height:100%;
        flex-direction:column;
        justify-content:space-between;

        .sell-left-inside{
            height:26%;
            width:60%;
            padding:0px 55px;
            display:flex;
            flex-direction:column;
            justify-content: center;
            position:relative;
            @media #{$sm-layout} {
                width:100%;
                height:100%;
                padding:16px 23px;
            }
            p{
                color: #0E1421 ;
                font-size:36px;
                margin:0;
                @media #{$sm-layout} {
                     display:block;
                     font-size:16px;
                     font-weight: 500;
                  }
                &:nth-child(2){
                  color:#2E2E2F;
                  font-size:20px;
                  display:none;
                  @media #{$sm-layout} {
                     display:block;
                     font-size:11px;
                     color:#BEBFC7 ;
                     margin-top:10px;
                  }
                }
                &:nth-child(3){
                  color:#0051FF ;
                  font-size:24px;
                  display:none;
                  position:absolute;
                  top:50px;
                  right:80px;

                }
            }
        }
        .bg-image25{
            p{
                color:#FFF;
                &:nth-child(2){
                  color:#AFB1CF;
                }
            }
        }
        .sell-left-inside:hover{
            height:40%;
            width:100%;
            transition: 0.8s;
            p:nth-child(2),p:nth-child(3){
                display:block;
                transition: 0.5s;
            }
            @media #{$sm-layout} {
                p:nth-child(3){
                    display:none;
                }
            }
        }
        .hovered1{
            height:40%;
            width:100%;
            transition: 0.8s;
            p:nth-child(2),p:nth-child(3){
                display:block;
                transition: 0.5s;
            }
            @media #{$sm-layout} {
                p:nth-child(3){
                    display:none;
                }
            } 
        }
    }
    .sell-right{
        @media #{$sm-layout} {
           display:none;
        }
    }
    .bg-image25 {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        background-image: url(../../assets/images/bg/bg-image-25.png);
    }
    .bg-hro,.bg-image24,.bg-image27{
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        background-image: url(../../assets/images/bg/bg-image-26.jpg);
        .container {
            ::v-deep .section-title{
                .subtitle{
                    display:none;
                }
                h2,.description{
                    color: #fff;
                }
            }
        }
    }
    .bg-hro{
        @media #{$sm-layout} {
           .sell-left-inside{
            background:#31375e;
            border-radius:10px;
            margin-top:10px;
            p:nth-child(1){
                color: #fff !important;;
            }
           }    .sell-right{
                    display:none;
                    }
        }
    }
   .bg-image24 {
        background-image: url(../../assets/images/bg/bg-image-24.jpg);
        ::v-deep .rn-card{
            border:none;
            .image{
                width:101%;
            }
        }
        .row2{
            justify-content: center;
        }
    }
    .bg-image27{
        background-image: url(../../assets/images/bg/bg-image-27.jpg);
    }
   .bg-arl {
        background:#f2f6fa ;
    }
    .single-slide-middle{
        .bg_image{
            width:50%;
            height:25%;
        }
    }
    //走马灯
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }
    
    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }
    
    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }
    .img-item{
        // position:relative;
        p{
            position:absolute;
            top:40%;
            text-align:center;
            width:100%;
            font-size:51px;
            color:#FFF;
        }
    }
    //走马灯
    .choose-arl{
        width:400px;
        height:242px;
        background:#FFF;
        border-radius:6px;
        text-align:center;
        padding:21px 42px;
        margin:20px 0;
        @media #{$sm-layout} {
            width:160px;
            height:100px;
            padding:14px 12px;
            margin:0;
        }
        &:hover{
             background:linear-gradient(to right, #2E37FF, #5BA0FF);
             div,p{
                color: #fff !important;;
             }
        }
        .row-left{
            align-items:center;
            div{
                color:#000;
                font-size:34px;
                @media #{$sm-layout} {
                  font-size:14px
                }
            }
            img{
                width:78px;
                height:78px;
                @media #{$sm-layout} {
                    width:20px;
                    height:20px;
                }
            }
        }
        p{
            color:#616879;
            font-size:19px;  
            margin-top:20px;
            @media #{$sm-layout} {
                font-size:8px;
                margin-top:10px;
            }
        }
    }
    .how-service-left{
        padding:0;
        border-radius:6px;
        margin:0 -44px 0 0 ;
        img{
            border-radius:20px;
        }

    }
    .how-service-right{
        background:#FFF;
        padding:16px 40px;
        border-radius:6px;
        @media #{$sm-layout} {
            padding:4px 26px;
        }
        .hovered{
            background:linear-gradient(to right, #FFF, #abcbf2); 
        }
        .row-left{
            display:flex;
            align-items:center;
            border-bottom:1px solid #d8d9dd;
            &:hover{
                background:linear-gradient(to right, #FFF, #abcbf2); 
                // border-radius:16px;
            }
            &:nth-last-child(1){
                border:none
            }
            img{
                width:30px;
                height:30px;
                @media #{$sm-layout} {
                    width:15px;
                    height:15px;
                }
            }
            div{
                margin-left:16px;
                padding:17px 0;
                @media #{$sm-layout} {
                    margin:0;
                    font-size:12px;
                    padding:10px 6px;
                }
    
            }
        }
    }
    .tiyan{
        .tiyan-inside{
            width:927px;
            height:91px;
            border-radius:46px;
            background:#FFF;
            margin:30px auto 30px auto;
           
            .inside-left{
                width:713px;
                font-size:30px;
                line-height:91px;
                padding-left:50px;
                color:#888;
            }
            .inside-right{
                width:214px;
                background:linear-gradient(120deg, #5BFBFF , #2E37FF );
                border-radius: 0 46px 46px 0 ;
                font-size:30px;
                line-height:91px;
                text-align:center;
                color:#FFF;
            }
        }
    }
    //移动端
    @media #{$sm-layout} {
      .arl-zn,.arl-zhaopin,.arl-service-left,.bg-arl-mobile{
            display:none;
        }
    }
</style>