<template>
    <div class="rn-card box-card-style-default"
         :class="{'card-list-view': blogPostStyle === 'list'}">
        <transition name="fade" mode="out-in">
           <div class="inner" v-if='showImage'>
                <div class="thumbnail" >
                    <!-- <router-link class="image" :to="`/blog-details/${blog.id}`"> -->
                    
                        <img  class="w-100" :src="blog.img" :alt="blog.title" />
                
                    <!-- </router-link> -->
                </div>
            <div class="content">
                <h4 class="title">
                    <router-link :to="`/blog-details/${blog.id}`">
                        {{ blog.title }}
                    </router-link>
                </h4>
                <h4 class="title title1">
                    <router-link :to="`/blog-details/${blog.id}`">
                        {{ blog.title1 }}
                    </router-link>
                </h4>
                <p v-if="blogPostStyle === 'list'" class="descriptiion">
                    {{ blog.excerpt }}
                </p>
            </div>
          </div>
        </transition> 
    </div>
</template>

<script>
    export default {
        name: 'BlogPost',
        props: {
            blog: {},
            blogPostStyle: {
                type: String,
                default: 'card'
            }
        },
        data(){
            return{
                showImage:true 
            }
        },
        watch:{
            'blog':{
                deep:true,
                handler(newValue,oldValue){
                    this.showImage = false;
                    setTimeout(() => {
                        this.showImage = true;
                    }, 0);
                }
            }
        }
    }
</script>
<stype lang="scss" scoped>
.rn-card{
  .inner{
    .content{
        position:absolute;
        top:20px;
        left:20px;
        .title{
            color:#FFF;
            font-size:40px !important;
    
        }
        .title1{
            margin-top:2px !important;
        }
    }
 }
}
</stype>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 3s ease;
}
 
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>