<template>
  <div class="freeReservation">
    <!-- <div class="freeReservation-left"></div> -->
    <div class="freeReservation-right">
      <div class="fr-main">
        <div class="fr-title">申请免费预约</div>
        <div class="fr-describe">请完善以下信息，爱人云顾问将在 1 个工作日内与您联系</div>
        <el-form ref="ruleFormRef" :model="form" class="demo-ruleForm">
          <el-form-item>
            <el-input v-model="form.input" placeholder="请输入您的姓名" />
          </el-form-item>
          <el-form-item><el-input v-model="form.input" placeholder="请输入您的企业全称" /></el-form-item>
          <el-form-item><el-input v-model="form.input" placeholder="请输入您的电话号码" /></el-form-item>
          <el-form-item><el-input v-model="form.input" placeholder="请输入您的短信验证码" /></el-form-item>
        </el-form>
        <div class="fr-btn">
          <el-button type="primary">登录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        input: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.freeReservation {
  width: 100vw;
  height: 100vh;
  display: flex;
  background-image: url("../assets/background.png");
  background-size: 100% 100%;
  // background: #fff;
  // .freeReservation-left {
  //   min-width: 500px;
  //   width: 50%;
  //   height: 100%;
  //   background: blue;
  // }

  .freeReservation-right {
    background: #0000007a;
    min-width: 550px;
    width: 100%;
    height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 13%;
    .fr-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 537px;
      height: 693px;
      background: rgba(164, 182, 223, 0.1);
      border-radius: 13px;
      .fr-title {
        display: flex;
        justify-content: center;
        font-size: 38.14px;
        font-weight: 500;
        color: #ffffff;
        font-family: PingFangSC, PingFang SC;
        letter-spacing: 11px;
      }
      .fr-describe {
        font-size: 16px;
        font-weight: 400;
        color: #9395A1;
        margin-top: 22px;
        margin-bottom: 47px;
      }
      .fr-btn {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0 53px;
        margin-top: 5px;
      }
    }

  }
}

::v-deep .el-form{
  width: 100% !important;
  padding: 0 53px;
}

::v-deep input {
  height: 63px !important;
  border: 1px solid #282A35 !important;
  border-radius: 5px;
  padding: 0 42px !important;
  color: #fff !important;
  // margin: 8px 0;
}
::v-deep input::placeholder {
  color: #9395A1 !important;
}
::v-deep .el-button {
  width: 100%;
  height: 61px;
  border-radius: 10px;
  font-size: 27px;
  font-weight: 500;
  font-family: PingFangSC, PingFang SC;
  margin-top: 32px;
}
::v-deep .el-form-item {
  margin-bottom: 23px !important;
}
::v-deep .el-button--primary {
  background-color: #0051FF;
  border-color: #0051FF;
}
</style>