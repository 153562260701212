<template>
    <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="true" :show-top-header="false" buy-button-class="btn-default btn-small round empty">
        <!-- Start 轮播 -->
        <div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow slider-text-middle">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-950 bg-overlay bg_image"
                         :style="{'background-image': `url(${require(`@/assets/images/arl-jzkh/${slider.image}.png`)})`}">
                        <div class="container">
                            <div class="row row--10 align-items-center">
                                <div class="order-2 order-lg-1 ">
                                    <div class="inner text-start">
                                        <h1 class="title" v-html="slider.title"/>
                                        <p class="description description-one" v-html="slider.description"/>
                                        <p class="description" v-html="slider.description1"/>
                                        <!-- <div class="button-group mt--30">
                                            <a class="btn-default"
                                               href="#">
                                                PURCHASE NOW
                                            </a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <!-- End 轮播 -->

        <!-- Start 爱人力 选择爱人力 -->
        <div class="blog-area rn-section-gap bg-image24 ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="企业在线管理方面遇到挑战"
                            data-aos="fade-up"
                        />
                    </div>
                </div>

               <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
                        data-aos="slide-up"
                        data-aos-duration="800"
                        :data-aos-delay="102"
                        v-for="(blog, index) in chooseArlList2"
                        :key="index"
                        v-if="index<3">
                        <div class="choose-arl choose-tiaozhan">
                
                            <div class="acea-row row-left">
                                <div>{{ blog.title }}</div>
                                <p v-if="isMobile">{{ blog.content }}</p>
                            </div>
                            <p v-if="!isMobile">{{ blog.content }}</p>
            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 爱人力 选择爱人力-->
        
        <!-- Start 爱人力 更智能 更轻简 更顺畅-->
        <div class="blog-area rn-section-gap arl-zn">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            :title="isMobile?'助力企业落地差异化服务':'标准化销售流程 实现业绩的精准预测'"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="100"
                         >
                        <div class="kehu-view">
                            <p>防抢 / 撞单，助力企业做好商机立项管理</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <div class="free-trial mobile-none">
                                免费试用 <Icon name="chevron-right" size="34"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 爱人力 更智能 更轻简 更顺畅 -->
        <!-- Start 爱人力 更智能 更轻简 更顺畅-->
        <div class="blog-area rn-section-gap arl-zn arl-jyxg arl-roi">
            <div class="container">
                <div class="row" v-if="isMobile">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="透明可控 科学推进 精细高效"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="100"
                         >
                        <div class="kehu-view">
                            <p>透明可控 科学推进 精细高效</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <div class="free-trial mobile-none">
                                免费试用 <Icon name="chevron-right" size="34"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 爱人力 更智能 更轻简 更顺畅 -->

        <!-- Start 爱人力 更智能 更轻简 更顺畅-->
        <div class="blog-area rn-section-gap arl-zn arl-jyxg">
            <div class="container">
                <div class="row" v-if="isMobile">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="提升业务流转效率 降低误输入"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="100"
                         >
                        <div class="kehu-view">
                            <p>提升业务流转效率 降低误输入</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <div class="free-trial mobile-none">
                                免费试用 <Icon name="chevron-right" size="34"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 爱人力 更智能 更轻简 更顺畅 -->

        <!-- Start 爱人力 更智能 更轻简 更顺畅-->
        <div class="blog-area rn-section-gap arl-zn arl-jyxg arl-roi">
            <div class="container">
                <div class="row" v-if="isMobile">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="评估业务经营状况，及时调整资源和策略"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="100"
                         >
                        <div class="kehu-view">
                            <p>评估业务经营状况，及时调整资源和策略</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <p>· 客户资料工商信息自动补全，工商数据完</p>
                            <div class="free-trial mobile-none">
                                免费试用 <Icon name="chevron-right" size="34"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 爱人力 更智能 更轻简 更顺畅 -->
        
        <!-- Start 爱人力-全员体验最好的平台 -->
        <div class="blog-area rn-section-gap bg-image27 bg-arl-mobile">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="爱人力 | 全员体验更好的人力资源SaaS平台"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="102">
                         <div class="tiyan">
                            <div class="tiyan-inside acea-row row-between">
                                <div class="inside-left">请输入您的电话</div>
                                <div class="inside-right">免费试用</div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End 爱人力-全员体验最好的平台-->
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import SectionTitle from '../../components/elements/sectionTitle-arl/SectionTitle'
    import Separator from '../../components/elements/separator/Separator'
    import VueSlickCarousel from 'vue-slick-carousel'
    import BlogPost from '../../components/blog/BlogPost-arl'
    import BlogPostMixin from '../../mixins/BlogPostMixin-arl'
    import Testimonial from '../../components/elements/testimonial/Testimonial'
    import Portfolio from '../../components/elements/portfolio/Portfolio'
    import ServiceFive from '../../components/elements/service/ServiceFive'
    import Progressbar from '../../components/elements/progressbar/Progressbar'
    import Icon from '../../components/icon/Icon'

    export default {
        name: 'DigitalAgency',
        components: {
            Icon,
            Progressbar,
            ServiceFive,
            Portfolio,
            Testimonial,
            BlogPost,
            Separator,
            SectionTitle,
            Layout,
            VueSlickCarousel
        },
        mixins: [BlogPostMixin],
        data() {
            return {
                chooseArlList:[
                    {
                        title:'自动化营销',
                        content:'服务体量涵盖广泛，包括劳务派遣、岗位外包、灵活用工等人力资源服务机构',
                        img: require(`@/assets/images/arl/choose-arl-1.png`),
                    },
                    {
                        title:'广告营销',
                        content:'服务体量涵盖广泛，包括劳务派遣、岗位外包、灵活用工等人力资源服务机构',
                        img: require(`@/assets/images/arl/choose-arl-2.png`),
                    },
                    {
                        title:'线索渠道评估',
                        content:'服务体量涵盖广泛，包括劳务派遣、岗位外包、灵活用工等人力资源服务机构',
                        img: require(`@/assets/images/arl/choose-arl-3.png`),
                    },
                    {
                        title:'客户360°视图',
                        content:'服务体量涵盖广泛，包括劳务派遣、岗位外包、灵活用工等人力资源服务机构',
                        img: require(`@/assets/images/arl/choose-arl-3.png`),
                    },
                    {
                        title:'客户跟进',
                        content:'服务体量涵盖广泛，包括劳务派遣、岗位外包、灵活用工等人力资源服务机构',
                        img: require(`@/assets/images/arl/choose-arl-3.png`),
                    },
                    {
                        title:'客户精准推荐',
                        content:'服务体量涵盖广泛，包括劳务派遣、岗位外包、灵活用工等人力资源服务机构',
                        img: require(`@/assets/images/arl/choose-arl-3.png`),
                    }
                ],
                chooseArlList1:[
                    {
                        title:'效率低下',
                        content:'服务体量涵盖广泛，包括劳务派遣、岗位外包、灵活用工等人力资源服务机构',
                        img: require(`@/assets/images/arl/choose-arl-4.png`),
                    },
                    {
                        title:'对账困难',
                        content:'服务体量涵盖广泛，包括劳务派遣、岗位外包、灵活用工等人力资源服务机构',
                        img: require(`@/assets/images/arl/choose-arl-5.png`),
                    }
                ],
                chooseArlList2:[
                    {
                        title:'1',
                        content:'服务体量涵盖广泛，包括劳务派遣、岗位外包、灵活用工等人力资源服务机构',
                        img: require(`@/assets/images/arl/choose-arl-4.png`),
                    },
                    {
                        title:'2',
                        content:'服务体量涵盖广泛，包括劳务派遣、岗位外包、灵活用工等人力资源服务机构',
                        img: require(`@/assets/images/arl/choose-arl-5.png`),
                    },
                    {
                        title:'3',
                        content:'服务体量涵盖广泛，包括劳务派遣、岗位外包、灵活用工等人力资源服务机构',
                        img: require(`@/assets/images/arl/choose-arl-4.png`),
                    },
                    {
                        title:'4',
                        content:'服务体量涵盖广泛，包括劳务派遣、岗位外包、灵活用工等人力资源服务机构',
                        img: require(`@/assets/images/arl/choose-arl-5.png`),
                    }
                ],
                blogObj:{
                    id: '4',
                    img: require(`@/assets/images/blog/blog-04.jpg`),
                    author: 'Jannin',
                    authorId: '4',
                    view: '70 views',
                    publishedAt: '25 Aug 2022',
                    // title: 'Top 5 Lessons About Corporate To Learn Before.',
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Marketing', 'UX Design', 'Business'],
                    tags: ['App', 'Business', 'Software', 'Design']
                },
                blogObj1:{
                    id: '5',
                    img: require(`@/assets/images/arl/hro1.png`),
                    author: 'Jannin',
                    authorId: '4',
                    view: '70 views',
                    publishedAt: '25 Aug 2022',
                    // title: 'Top 5 Lessons About Corporate To Learn Before.',
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Marketing', 'UX Design', 'Business'],
                    tags: ['App', 'Business', 'Software', 'Design']
                },
                howServiceImg:require('@/assets/images/arl/how-service-1.png'),
                sliderSetting: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 993,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                },
                sliderSetting1: {
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    autoplay: true, // 自动播放
                    autoplaySpeed: 5000, // 自动播放速度
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 993,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                },
                sliderData: [
                    {
                        image: 'bg-6',
                        title: '盈利商机',
                        description: '通过标准化销售流程、联系人图谱、商机作战通过标准化销售流程、联系人图谱、商机作战',
                        description1: '通过标准化销售流程、联系人图谱、商机作战'
                    }
                ],
                testimonialData: [
                    {
                        image: 'testimonial-01',
                        name: 'Sr Janen Sara',
                        designation: 'Sr Product Designer',
                        location: 'CO Miego, AD, USA',
                        description: '“Proin libero vel lorem dui lupus est aliquet luctus purus justo eget libero sed lorem.„',
                    },
                    {
                        image: 'testimonial-02',
                        name: 'Afsana Nila',
                        designation: 'App Developer',
                        location: 'Bangladesh',
                        description: '“Proin libero vel lorem dui lupus est aliquet luctus purus justo eget libero sed lorem.„',
                    },
                    {
                        image: 'testimonial-03',
                        name: 'Afanan Sifa',
                        designation: 'Accounts Manager',
                        location: 'Poland',
                        description: '“Proin libero vel lorem dui lupus est aliquet luctus purus justo eget libero sed lorem.„',
                    },
                ],
                progressCircleData: [
                    {
                        id: 1,
                        title: 'Design',
                        percentage: 85,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 2,
                        title: 'Photoshop',
                        percentage: 60,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 3,
                        title: "Application",
                        percentage: 70,
                        subtitle: "Presentation your skill",
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                    {
                        id: 4,
                        title: 'Development',
                        percentage: 95,
                        subtitle: 'Presentation your skill',
                        strokeColor: '#0f0f11',
                        strokePath: '#059DFF'
                    },
                ],
                portfolioData:  [
                    {
                        id: 1,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        title: 'App Development',
                        subtitle: 'Branded client',
                        date: '30 May 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 2,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        title: 'Business Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 3,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        title: 'Photoshop Design',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'art',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 4,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        title: 'Native Application',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-04.jpg`)
                        }
                    },
                    {
                        id: 5,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        title: 'Vue Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 6,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        title: 'App Installment',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-06.jpg`)
                        }
                    }
                ]
            }
        },
        methods:{
            handleMouseEnter(v) {
              this.howServiceImg = require(`@/assets/images/arl/how-service-${v+1}.png`)
            },
            handleMouseEnter1(v) {
              this.blogObj.img = require(`@/assets/images/arl/masakaI${v}.png`)
            },
            handleMouseEnter2(v) {
              this.blogObj1.img = require(`@/assets/images/arl/hro${v}.png`)
            }
        }
    }
</script>
<style lang="scss" scoped>
    $sm-layout:'only screen and (max-width: 767px)';
    .bg-image22,.choose-tiaozhan {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        background-image: url(../../assets/images/bg/bg-image-22.jpg);
    }
    .bg-image23 {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        background-image: url(../../assets/images/bg/bg-image-23.png);
        @media #{$sm-layout} {
            background: #FFF;
            border-radius:10px;
            height:96px;
            margin-top:10px;
        }
    }
    .sell-left{
        display:flex;
        height:100%;
        flex-direction:column;
        justify-content:space-between;

        .sell-left-inside{
            height:26%;
            width:60%;
            padding:0px 55px;
            display:flex;
            flex-direction:column;
            justify-content: center;
            position:relative;
            @media #{$sm-layout} {
                width:100%;
                height:100%;
                padding:16px 23px;
            }
            p{
                color: #0E1421 ;
                font-size:36px;
                margin:0;
                @media #{$sm-layout} {
                     display:block;
                     font-size:16px;
                     font-weight: 500;
                  }
                &:nth-child(2){
                  color:#2E2E2F;
                  font-size:20px;
                  display:none;
                  @media #{$sm-layout} {
                     display:block;
                     font-size:11px;
                     color:#BEBFC7 ;
                     margin-top:10px;
                  }
                }
                &:nth-child(3){
                  color:#0051FF ;
                  font-size:24px;
                  display:none;
                  position:absolute;
                  top:50px;
                  right:80px;

                }
            }
        }
        .bg-image25{
            p{
                color:#FFF;
                &:nth-child(2){
                  color:#AFB1CF;
                }
            }
        }
        .sell-left-inside:hover{
            height:40%;
            width:100%;
            transition: 0.8s;
            p:nth-child(2),p:nth-child(3){
                display:block;
                transition: 0.5s;
            }
            @media #{$sm-layout} {
                p:nth-child(3){
                    display:none;
                }
            }
        }
    }
    .sell-right{
        @media #{$sm-layout} {
           display:none;
        }
    }
    .bg-image25 {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        background-image: url(../../assets/images/bg/bg-image-25.png);
    }
    .bg-hro,.bg-image27{
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        background-image: url(../../assets/images/bg/bg-image-26.jpg);
        .container {
            ::v-deep .section-title{
                .subtitle{
                    display:none;
                }
                h2,.description{
                    color: #fff;
                }
            }
        }
    }
    .bg-hro{
        @media #{$sm-layout} {
           .sell-left-inside{
            background:#31375e;
            border-radius:10px;
            margin-top:10px;
            p:nth-child(1){
                color: #fff !important;;
            }
           }    .sell-right{
                    display:none;
                    }
        }
    }
   .bg-image24 {
 
      .container {
            ::v-deep .section-title{
                    .subtitle{
                        display:none;
                    }
                    h2,.description{
                        @media #{$sm-layout} {
                            font-size:20px;
                        }
                }
            }
        }
         background-color:#F3F3F5;
        ::v-deep .rn-card{
            border:none;
            .image{
                width:101%;
            }
        }
        .row2{
            justify-content: center;
        }
    }
    .bg-image27{
        background-image: url(../../assets/images/bg/bg-image-27.jpg);
    }
   .bg-arl {
        background:#f2f6fa ;
    }
    .single-slide-middle{
        .bg_image{
            width:50%;
            height:25%;
        }
    }
    //走马灯
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }
    
    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }
    
    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }
    .img-item{
        // position:relative;
        p{
            position:absolute;
            top:40%;
            text-align:center;
            width:100%;
            font-size:51px;
            color:#FFF;
        }
    }
    //走马灯
    .choose-arl{
        width:400px;
        height:242px;
        background:#FFF;
        border-radius:6px;
        text-align:center;
        padding:21px 42px;
        margin:20px 0;
        @media #{$sm-layout} {
            width:160px;
            height:100px;
            padding:14px 12px;
            margin:0;
        }
        &:hover{
            //  background:linear-gradient(to right, #2E37FF, #5BA0FF);
             div,p{
                color: #fff !important;;
             }
        }
        .row-left{
            align-items:center;
            div{
                color:#000;
                font-size:34px;
                @media #{$sm-layout} {
                  font-size:14px
                }
            }
            img{
                width:78px;
                height:78px;
                @media #{$sm-layout} {
                    width:20px;
                    height:20px;
                }
            }
        }
        p{
            color:#616879;
            font-size:19px;  
            margin-top:20px;
            @media #{$sm-layout} {
                font-size:8px;
                margin-top:10px;
            }
        }
    }
    .choose-tiaozhan{
        background-image: url(../../assets/images/arl-jzkh/bg-1.png);
        background-size:cover;
        width:316px;
        height:340px;
        @media #{$sm-layout} {
            width:343px;
            height:116px;
            margin-top:-20px;
        }
        &:hover{
            background-blend-mode: multiply;
            background-color: rgba(58, 58, 89, 1);
        }
        .row-left{
            align-items:center;
            div{
                font-size:71px;
                font-family: AlibabaPuHuiTiH;
                font-weight:600;
                @media #{$sm-layout} {
                  font-size:60px;
                }
            }
            @media #{$sm-layout} {
                p{
                    width:80%;
                    text-align:left;
                    margin-left:20px;
                    font-size:12px;
                    color:#000;
                }
            }

        }
        p{
            margin:0;
            color:#000;
        }
    }
    .choose-arl-tuijian{
        .left-icon{
            width:12px;
            height:34px;
            background:linear-gradient(to bottom, #5BA0FF, #2E37FF);
            border-radius:7px;
            margin-right:18px;
        }
        &:hover{
            background:linear-gradient(to right, #2E37FF, #5BA0FF);
            .left-icon{
                background:linear-gradient(to bottom, #88BAFF, #FFF);
            }
        }
        }

    .arl-zn{
        .container {
            ::v-deep .section-title{
                    .subtitle{
                        display:none;
                    }
                    h2,.description{
                        @media #{$sm-layout} {
                            font-size:20px;
                        }
                    }
                }
        }
        .kehu-view{
            background:#FFF url(../../assets/images/arl-jzkh/bg-2.png) no-repeat 200px 0 / 100% 100% border-box border-box fixed;
            padding:20px 0;
            @media #{$sm-layout} {
                background:#FFF;
                padding:6px 20px;
                border-radius:6px;
                margin-top:-20px;
            }
   
            p{
                color:#6D6D6D;
                font-size:20px;
                margin:22px 0;
                @media #{$sm-layout} {
                    font-size:14px;
                    color:#626E74 ;
                }
                &:nth-child(1){
                    color:#000;
                    font-size:36px;
                    margin:18px 0 26px 0;
                    @media #{$sm-layout} {
                        display:none;
                    }
                }
                &:nth-last-child(2){
                    margin-bottom:40px;
                }
            }
        }
        
    }
    .arl-khzy{
        background: url(../../assets/images/arl-jzkh/bg-3.png) no-repeat center center/100% 100%;
        @media #{$sm-layout} {
            .container {
                ::v-deep .section-title{
                    .subtitle{
                        display:none;
                    }
                    h2,.description{
                        color: #fff;
                        font-size:20px;
                    }
                }
            }
        }
        .kehu-view{
            background:none;
            padding-left:50%;
            @media #{$sm-layout} {
                padding-left:0;
                background:#7578A6;
                color:#32355B;
                padding:1px 20px;
            }
        }
        p{
            color: #98A7C6!important;
            &:nth-child(1){
                color: #FFF !important;;
            }
    
        }
    }
    .arl-jyxg{
        background: url(../../assets/images/arl-jzkh/bg-4.png) no-repeat center center/100% 100%;
        .kehu-view{
            background:none;
            @media #{$sm-layout} {
                padding-left:0;
                background:#fff;
                color:#626E74;
                padding:1px 20px;
            }
        }
    }
    .arl-roi{
        background: url(../../assets/images/arl-jzkh/bg-5.png) no-repeat center center/100% 100%;
        @media #{$sm-layout} {
            background:#222548 ;
            .container {
                ::v-deep .section-title{
                        h2,.description{
                            color:#FFF;
                        }
                    }
                }
            }
        .kehu-view{
            padding-left:50%;
            @media #{$sm-layout} {
                padding-left:0;
                background:#fff;
                color:#626E74;
                padding:1px 20px;
            }
        }
    }
    .how-service-left{
        padding:0;
        border-radius:6px;
        margin:0 -44px 0 0 ;
        img{
            border-radius:20px;
        }

    }
    .slider-text-middle{
        .container{
            .title,.description{
                text-align:center;
            }
            @media #{$sm-layout} {
                .title{
                    font-size:35px;
                }
                .description{
                    font-size:12px;
                    margin-top:-16px;
                }
            }
        }
    }
    .how-service-right{
        background:#FFF;
        padding:16px 40px;
        border-radius:6px;
        @media #{$sm-layout} {
            padding:4px 26px;
        }
        .row-left{
            display:flex;
            align-items:center;
            border-bottom:1px solid #d8d9dd;
            &:hover{
                background:linear-gradient(to right, #FFF, #abcbf2); 
                // border-radius:16px;
            }
            &:nth-last-child(1){
                border:none
            }
            img{
                width:30px;
                height:30px;
                @media #{$sm-layout} {
                    width:15px;
                    height:15px;
                }
            }
            div{
                margin-left:16px;
                padding:17px 0;
                @media #{$sm-layout} {
                    margin:0;
                    font-size:12px;
                    padding:10px 6px;
                }
    
            }
        }
    }
    .tiyan{
        .tiyan-inside{
            width:927px;
            height:91px;
            border-radius:46px;
            background:#FFF;
            margin:30px auto 30px auto;
           
            .inside-left{
                width:713px;
                font-size:30px;
                line-height:91px;
                padding-left:50px;
                color:#888;
            }
            .inside-right{
                width:214px;
                background:linear-gradient(120deg, #5BFBFF , #2E37FF );
                border-radius: 0 46px 46px 0 ;
                font-size:30px;
                line-height:91px;
                text-align:center;
                color:#FFF;
            }
        }
    }
    //移动端
    @media #{$sm-layout} {
      .arl-service-left,.bg-arl-mobile{
            display:none;
        }
    }
    .free-trial{
            border-radius:49px;
            display:inline-block;
            padding:10px 30px 10px 37px;
            font-size:24px;
            border:2px solid #0051FF;
            color:#0051FF;
            font-weight:500;
            .size-34{
                margin-bottom:8px;
            }
        }
        .free-trial-white{
            color:#FFF;
            border:2px solid #FFF;
        }
</style>